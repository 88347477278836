/*!
 * home.js
 */
require('lib/flash-message');
import 'bootstrap/js/modal.js';

var cookie = require('lib/cookie');

//Initialise the homepage Promo Modal
$(function(){
    var promoModal = "promo-modal";
    var $modal = $('#promo-modal-lg');

    if($modal.length){
        $modal.modal();
    }
});